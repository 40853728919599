import React, { useEffect, useState } from 'react';
import '../styles/Preloader.css';

const Preloader = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Simuler une progression de chargement
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress + Math.random() * 10;
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="preloader">
      <div className="preloader-content">
        <h1>ZONE BLANCHE</h1>
        <div className="progress-bar">
          <div 
            className="progress-fill" 
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <div className="loading-text">Chargement {Math.floor(progress)}%</div>
      </div>
    </div>
  );
};

export default Preloader;