import React, { useEffect, useRef } from 'react';

const AudioPlayer = ({ enabled, setEnabled }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      if (enabled) {
        audioRef.current.play().catch(e => {
          console.log('Autoplay prevented:', e);
          setEnabled(false);
        });
      } else {
        audioRef.current.pause();
      }
    }
  }, [enabled, setEnabled]);

  return (
    <audio 
      ref={audioRef} 
      src="/assets/sounds/ambient.mp3" 
      loop 
      className="background-audio"
    />
  );
};

export default AudioPlayer;