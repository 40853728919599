import React, { useState, useEffect } from 'react';
import '../styles/Header.css';

const Header = ({ audioEnabled, setAudioEnabled }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <header className={`site-header ${scrolled ? 'scrolled' : ''}`}>
      <div className="header-container">
        <div className="logo">ZONE BLANCHE</div>
        <nav>
          <ul>
            <li><a href="#manifesto">Manifeste</a></li>
            <li><a href="#section-1">Transparence</a></li>
            <li><a href="#section-2">Confiance</a></li>
            <li><a href="#section-3">Innovation</a></li>
            <li>
              <button 
                className={`audio-toggle ${audioEnabled ? 'on' : 'off'}`}
                onClick={() => setAudioEnabled(!audioEnabled)}
              >
                {audioEnabled ? '🔊' : '🔇'}
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;