import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Section from './components/Section';
import Preloader from './components/Preloader';
import SmoothScroll from './components/SmoothScroll';
import AudioPlayer from './components/AudioPlayer';
import './styles/App.css';

function App() {
  const [loading, setLoading] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(false);

  useEffect(() => {
    // Simuler un temps de chargement pour le préchargeur
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const sections = [
    {
      id: 'manifesto',
      title: 'ZONE BLANCHE',
      subtitle: 'Manifeste',
      background: '/assets/videos/background1.mp4',
      content: `Un espace de liberté numérique. Une zone où la technologie sert l'humain et non l'inverse.`
    },
    {
      id: 'section-1',
      title: 'Transparence',
      subtitle: 'Notre engagement',
      background: '/assets/images/background2.jpg',
      content: `Dans un monde saturé d'informations, nous créons un espace où la clarté prévaut. Chaque décision, chaque ligne de code, chaque interaction est guidée par une volonté de transparence absolue.`
    },
    {
      id: 'section-2',
      title: 'Confiance',
      subtitle: 'Notre fondation',
      background: '/assets/images/background3.jpg',
      content: `La confiance ne se décrète pas, elle se construit. Jour après jour, interaction après interaction, nous bâtissons une relation de confiance avec notre communauté, basée sur le respect et l'intégrité.`
    },
    {
      id: 'section-3',
      title: 'Innovation',
      subtitle: 'Notre moteur',
      background: '/assets/videos/background4.mp4',
      content: `L'innovation n'est pas seulement technologique, elle est avant tout humaine. Nous repoussons constamment les limites pour créer des expériences qui résonnent avec nos valeurs fondamentales.`
    }
  ];

  return (
    <div className="App">
      {loading ? (
        <Preloader />
      ) : (
        <SmoothScroll>
          <AudioPlayer enabled={audioEnabled} setEnabled={setAudioEnabled} />
          <Header audioEnabled={audioEnabled} setAudioEnabled={setAudioEnabled} />
          {sections.map((section, index) => (
            <Section 
              key={section.id}
              id={section.id}
              title={section.title}
              subtitle={section.subtitle}
              background={section.background}
              content={section.content}
              index={index}
            />
          ))}
        </SmoothScroll>
      )}
    </div>
  );
}

export default App;