import React, { useEffect, useRef } from 'react';
import '../styles/Section.css';

const Section = ({ id, title, subtitle, background, content, index }) => {
  const sectionRef = useRef(null);
  const isVideo = background.endsWith('.mp4');
  const delay = index * 0.2; // Delay progressif pour chaque section

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          sectionRef.current.classList.add('visible');
          
          // Jouer la vidéo si c'est une vidéo et qu'elle est visible
          if (isVideo) {
            const video = sectionRef.current.querySelector('video');
            if (video) {
              video.play().catch(e => console.log('Autoplay prevented:', e));
            }
          }
        } else {
          sectionRef.current.classList.remove('visible');
          
          // Mettre en pause la vidéo si elle n'est plus visible
          if (isVideo) {
            const video = sectionRef.current.querySelector('video');
            if (video) {
              video.pause();
            }
          }
        }
      },
      { threshold: 0.3 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [isVideo]);

  return (
    <section id={id} ref={sectionRef} className="section" data-speed={0.1 + index * 0.05}>
      <div className="section-background">
        {isVideo ? (
          <video 
            src={background} 
            autoPlay={false} 
            loop 
            muted 
            playsInline
            className="background-video"
          />
        ) : (
          <div 
            className="background-image" 
            style={{ backgroundImage: `url(${background})` }}
          />
        )}
      </div>
      <div className="section-content" style={{ transitionDelay: `${delay}s` }}>
        <div className="section-header">
          <h2>{subtitle}</h2>
          <h1>{title}</h1>
        </div>
        <div className="section-text">
          <p>{content}</p>
        </div>
      </div>
    </section>
  );
};

export default Section;