import React, { useEffect, useRef } from 'react';

const SmoothScroll = ({ children }) => {
  const scrollingContainerRef = useRef();
  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0
  };

  useEffect(() => {
    requestAnimationFrame(() => smoothScrolling());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setBodyHeight = () => {
    document.body.style.height = `${scrollingContainerRef.current.getBoundingClientRect().height}px`;
  };

  useEffect(() => {
    setBodyHeight();
    window.addEventListener('resize', setBodyHeight);
    return () => window.removeEventListener('resize', setBodyHeight);
  }, []);

  const smoothScrolling = () => {
    data.current = window.scrollY;
    data.previous += (data.current - data.previous) * data.ease;
    data.rounded = Math.round(data.previous * 100) / 100;

    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.style.transform = `translateY(-${data.rounded}px)`;
    }

    // Ajouter parallax et animations basées sur le défilement
    const sections = document.querySelectorAll('.section');
    sections.forEach(section => {
      const speed = section.dataset.speed || 0.2;
      const top = section.getBoundingClientRect().top + data.rounded;
      const offset = top * speed;
      
      const background = section.querySelector('.section-background');
      if (background) {
        background.style.transform = `translateY(${offset}px)`;
      }
      
      // Fade in/out basé sur la position
      const opacity = calculateOpacity(section, data.rounded);
      section.style.opacity = opacity;
    });

    requestAnimationFrame(() => smoothScrolling());
  };

  const calculateOpacity = (element, scrollPos) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    
    // L'élément est visible quand il est dans la fenêtre
    if (rect.top < windowHeight && rect.bottom > 0) {
      // Calculer l'opacité basée sur la position relative
      const distanceFromCenter = Math.abs(windowHeight / 2 - (rect.top + rect.height / 2));
      const maxDistance = windowHeight / 2 + rect.height / 2;
      return 1 - Math.min(distanceFromCenter / maxDistance, 0.3);
    }
    
    return 0.7; // Opacité minimale pour les sections non visibles
  };

  return (
    <div className="parent">
      <div ref={scrollingContainerRef} className="smooth-scroll">
        {children}
      </div>
    </div>
  );
};

export default SmoothScroll;